import { Page, View } from '@react-pdf/renderer';
import { PdfHeader } from '../pdfHeader';
import { IPage } from '../performancePage';
import { PdfChart } from '../pdfChart';
import { PdfFooter } from '../pdfFooter';

export const ChartPage = ({ image, pageNumber, titles }: IPage) => {
  return (
    <Page size='A4' orientation='landscape'>
      <View style={{ maxHeight: '580px' }}>
        <PdfHeader />
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            padding: '0 24px',
            marginTop: '4px'
          }}
        >
          <PdfChart title={(titles || [])[0] || ''} img={image[0]} />
          <PdfChart title={(titles || [])[1] || ''} img={image[1]} />
          <View>
            <PdfFooter pageNumber={pageNumber} />
          </View>
        </View>
      </View>
    </Page>
  );
};
