export const mockedBarChartOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  skipNull: true,
  plugins: {
    legend: {
      onClick: () => {},
      display: false
    },

    tooltip: {
      callbacks: {
        label: function (context: any) {
          return `${context.dataset.label}: ${context.raw}%`;
        }
      }
    },
    datalabels: {
      display: true,
      anchor: 'end',
      align: 'end',

      formatter: (value: number) => (value !== null ? `${value}%` : ''),
      font: {
        family: 'Iconiq Quadraat',
        size: 16
      },
      color: 'black'
    }
  },
  layout: {
    padding: {
      top: 45,
      bottom: 28,
      left: 10,
      right: 10
    }
  },

  scales: {
    x: {
      grid: {
        drawTicks: true,
        display: false
      },
      ticks: {
        display: true,

        font: {
          weight: 'normal',
          family: 'Iconiq Quadraat',
          size: 12
        },
        maxRotation: 0
      },
      border: {
        display: false
      }
    },
    y: {
      grid: {
        drawTicks: false,
        display: false
      },
      ticks: {
        display: false
      },
      border: {
        display: false
      }
    }
  }
};

export const pricingModelData = {
  title: 'Pricing Model',
  labels: ['Subscription', 'Usage-Based', 'Hybrid'],
  datasets: [
    {
      label: 'Subscription',
      data: [55, null, null],
      backgroundColor: '#88D2A0'
    },
    {
      label: 'Usage-Based',
      data: [null, 32, null],
      backgroundColor: '#88D2A0'
    },
    {
      label: 'Hybrid',
      data: [null, null, 13],
      backgroundColor: '#88D2A0'
    }
  ]
};

export const subSectorData = {
  title: 'Sub-Sector',
  labels: [
    ['Horizontal', 'SaaS'],
    'Infrastructure',
    'Fintech',
    'Healthcare',
    ['Vertical', 'SaaS']
  ],
  datasets: [
    {
      label: 'Horizontal SaaS',
      data: [36, null, null, null, null],
      backgroundColor: '#438998'
    },
    {
      label: 'Infrastructure',
      data: [null, 24, null, null, null],
      backgroundColor: '#438998'
    },
    {
      label: 'Fintech',
      data: [null, null, 16, null, null],
      backgroundColor: '#438998'
    },
    {
      label: 'Healthcare',
      data: [null, null, null, 12, null],
      backgroundColor: '#438998'
    },
    {
      label: 'Vertical SaaS',
      data: [null, null, null, null, 12],
      backgroundColor: '#438998'
    }
  ]
};
