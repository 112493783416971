const LongerDisclousure = () => {
  return (
    <div className='w-full flex'>
      <p className='text-xs font-light text-[#5C5C5C]'>
        1. The Saas Benchmark reflects data gathered from all ICONIQ Growth
        Portfolio companies where data was available as well as data from a
        sampling of public B2B SaaS comp. This data does not, and should not be
        taken to, represent the performance of any ICONIQ Fund or investment
        program and must not be relied upon in connection with any investment
        decision. <br /> <br />
        Unless otherwise indicated, communications on this website are issued by
        ICONIQ Capital, LLC (“ICONIQ Capital”). <br /> <br />
        The views expressed in this tool are those of ICONIQ Growth (“ICONIQ" or
        the “Firm"), an affiliate of ICONIQ Capital, are the result of
        proprietary research, may be subjective, and may not be relied upon in
        making an investment decision. Information used in this tool was
        obtained from numerous sources. ICONIQ Growth does not make any
        representations or warranties as to the accuracy of the information
        obtained from these sources. <br /> <br />
        This tool is for general information purposes only and does not
        constitute investment advice. This tool must not be relied upon in
        connection with any investment decision. The information in this tool is
        not intended to and does not constitute financial, accounting, tax,
        legal, investment, consulting or other professional advice or services.
        Nothing in this tool is or should be construed as an offer, invitation
        or solicitation to engage in any investment activity or transaction,
        including an offer to sell or a solicitation of an offer to buy any
        securities which should only be made pursuant to definitive offering
        documents and subscription agreements, including without limitation, any
        investment fund, investment service or investment product referenced
        herein. <br /> <br /> This tool may contain forward-looking statements
        based on current plans, estimates and projections. The user of this tool
        ("you") are cautioned that a number of important factors could cause
        actual results or outcomes to differ materially from those expressed in,
        or implied by, the forward-looking statements. The numbers, figures and
        case studies included in this tool have been included for purposes of
        illustration only, and no assurance can be given that the actual results
        of ICONIQ or any of its partners and affiliates will correspond with the
        results contemplated in the tool. Past performance is not a reliable
        indicator of future performance, and any investment may result in the
        loss of all or some of the amounts invested. <br /> <br /> All of the
        information in the tool is presented as of the date made available to
        you (except as otherwise specified), and is subject to change without
        notice, and may not be current or may have changed (possibly materially)
        between the date made available to you and the date actually received or
        reviewed by you. ICONIQ assumes no obligation to update or otherwise
        revise any information, projections, forecasts or estimates contained in
        the tool, including any revisions to reflect changes in economic or
        market conditions or other circumstances arising after the date the
        items were made available to you or to reflect the occurrence of
        unanticipated events. <br /> <br /> For avoidance of doubt, ICONIQ is
        not acting as an adviser or fiduciary in any respect in connection with
        providing this tool and no relationship shall arise between you and
        ICONIQ as a result of this tool being made available to you. 
        <br /> <br />  ICONIQ is a diversified financial services firm and has
        direct client relationships with persons that may become limited
        partners of ICONIQ funds. Notwithstanding that a person may be referred
        to herein as a "client" of the firm, no limited partner of any fund
        will, in its capacity as such, be a client of ICONIQ. There can be no
        assurance that the investments made by any ICONIQ fund will be
        profitable or will equal the performance of prior investments made by
        persons described in this tool.  <br /> <br />
        Any information in this tool is directed at, and intended for, only
        persons who are experienced institutional or professional investors
        (“professional investors”) as defined by applicable law and regulation.
        Any person that is not a professional investor is not an intended
        recipient of this tool and the matters discussed herein. This tool and
        any information therein is not intended for the use of and is not
        available to any person located, established or resident in the European
        Economic Area, and the use of the tool by any such person is not
        authorised by ICONIQ.
        <br /> <br /> This tool is administered by ICONIQ Capital and is
        therefore not administered by a person that is authorised, registered,
        endorsed or recognized under UK or EEA domestic laws applying Regulation
        2016/1011 (including as retained as part of UK law), and the tool is not
        authorised under Regulation 2016/1011. Consequently, certain users may
        be restricted from using this tool.
      </p>
    </div>
  );
};

export default LongerDisclousure;
