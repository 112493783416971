import { FilterOption } from 'interfaces/components/filter';
import {
  ChartDataMap,
  ChartLabelsV2,
  CompanyMetrics,
  mapChartData
} from 'utils/helpers/mapChartsData';

export interface Dataset {
  label: string;
  data: (number | null | undefined)[];
  backgroundColor: string;
}

interface ChartData {
  title: string;
  labels: string[];
  datasets: Dataset[];
}

interface ProcessedChartData {
  labels: string[];
  datasets: Dataset[];
}

export const getDataCharts = (
  switchValue: number,
  chartData: ChartDataMap[],
  companyMetrics: CompanyMetrics,
  arr: FilterOption[],
  growthMotion: FilterOption[],
  softwareSector: FilterOption[],
  primaryCustomer: FilterOption[]
) => {
  const { datasets, datasetsStacked } = mapChartData(
    chartData,
    companyMetrics,
    arr,
    growthMotion,
    softwareSector,
    primaryCustomer
  );
  //checks if yourcompany array exists
  const yourCompanyVerifyDatasets = (data: any[], switchValue: number) => {
    const swappedData = [data[0], data[1], data[2] ?? null];
    return [swappedData[switchValue], swappedData[2]].filter(Boolean);
  };

  //checks if yourcompany array exists for stacked bars
  const yourCompanyVerifyStackedDatasets = (
    data: any[][],
    switchValue: number
  ) => {
    const swappedData = [data[0], data[1], data[2]].filter(Boolean);

    const youCompanyArray = swappedData[2]
      ? swappedData[2].filter(Boolean)
      : [];

    return [...swappedData[switchValue], ...youCompanyArray];
  };
  //remove null data
  const filterNullValues = (
    labels: string[],
    datasets: Dataset[]
  ): ProcessedChartData => {
    const filteredLabels: string[] = [];
    const filteredDatasets: Dataset[] = datasets.map(dataset => ({
      ...dataset,
      data: [] as (number | null)[]
    }));
    if (arr.length > 0) {
      arr.forEach((label, index) => {
        const isNullInAll = datasets.every(
          dataset => dataset?.data?.[index] === null
        );

        if (!isNullInAll) {
          filteredLabels.push(label.value);
          datasets.forEach((dataset, datasetIndex) => {
            filteredDatasets[datasetIndex]?.data.push(dataset?.data?.[index]);
          });
        }
      });
    } else {
      labels.forEach((label, index) => {
        const isNullInAll = datasets.every(
          dataset => dataset?.data?.[index] === null
        );

        if (!isNullInAll) {
          filteredLabels.push(label);
          datasets.forEach((dataset, datasetIndex) => {
            filteredDatasets[datasetIndex]?.data.push(dataset?.data?.[index]);
          });
        }
      });
    }

    return {
      labels: arr.length > 0 ? filteredLabels : labels,
      datasets: filteredDatasets
    };
  };

  const processChartData = (
    labels: string[],
    datasets: Dataset[]
  ): ProcessedChartData => {
    return filterNullValues(labels, datasets);
  };

  const barChartData = [
    {
      title: 'YoY ARR Growth',
      ...processChartData(
        ChartLabelsV2,
        yourCompanyVerifyDatasets(datasets.YoYARRGrowth, switchValue)
      )
    },
    {
      title: 'Net Dollar Retention',
      ...processChartData(
        ChartLabelsV2,
        yourCompanyVerifyDatasets(datasets.netDollarRetention, switchValue)
      )
    },

    {
      title: 'Net Magic Number',
      ...processChartData(
        ChartLabelsV2,
        yourCompanyVerifyDatasets(datasets.netMagicNumber, switchValue)
      )
    },

    {
      title: 'Gross Margin',
      ...processChartData(
        ChartLabelsV2,
        yourCompanyVerifyDatasets(datasets.grossMargin, switchValue)
      )
    },
    {
      title: 'Burn Multiple',
      ...processChartData(
        ChartLabelsV2,
        yourCompanyVerifyDatasets(datasets.burnMultiple, switchValue)
      )
    },
    {
      title: 'ARR per FTE',
      ...processChartData(
        ChartLabelsV2,
        yourCompanyVerifyDatasets(datasets.ARRperFTE, switchValue)
      )
    },
    {
      title: 'OpEx as a % of Revenue',
      ...processChartData(ChartLabelsV2, [
        ...yourCompanyVerifyStackedDatasets(datasetsStacked.OpEx, 1)
      ])
    },
    {
      title: 'FCF Margin',
      ...processChartData(
        ChartLabelsV2,
        yourCompanyVerifyDatasets(datasets.FCFMargin, switchValue)
      )
    },
    {
      title: 'Quick Ratio',
      ...processChartData(
        ChartLabelsV2,
        yourCompanyVerifyDatasets(datasets.quickRatio, switchValue)
      )
    },
    {
      title: 'Headcount Distribution',
      ...processChartData(ChartLabelsV2, [
        ...yourCompanyVerifyStackedDatasets(
          datasetsStacked.headcountDistribution,
          0
        )
      ])
    }
  ];

  const bubbleChartData = [
    {
      title: 'Rule of 40',
      datasets: yourCompanyVerifyDatasets(datasets.ruleOf40, switchValue)
    }
  ];

  return {
    barChartData,
    bubbleChartData
  };
};
