import { Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { getPreviousQuarter } from 'utils/helpers';
interface IPdfFooter {
  pageNumber: number;
}
export const PdfFooter = ({ pageNumber }: IPdfFooter) => {
  return (
    <View
      style={{
        borderTop: '1px solid #dddddd',
        width: '794px',
        height: '22px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: '10px',
        color: '#5c5c5c'
      }}
    >
      <Text>{pageNumber} of 8</Text>
      <View
        style={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}
      >
        <Text>
          Report Generated on {dayjs(new Date()).format('MMMM D, YYYY')}
        </Text>
        <Text>{`Data last refreshed as of ${getPreviousQuarter()}`}</Text>
      </View>
    </View>
  );
};
