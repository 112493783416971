import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const loadingChartSlice = createSlice({
  name: 'loadingChart',
  initialState: {
    isLoading: true
  },
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    }
  }
});

export const { setIsLoading } = loadingChartSlice.actions;

export default loadingChartSlice.reducer;
