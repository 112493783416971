import ReactModal from 'react-modal';
import { IConfirmationModal } from 'interfaces/components/confirmationModal';

import LoadingComponent from 'components/SectionCharts/LoadingComponent';

const LoadingModal = ({ isOpen, onClose, onReset }: IConfirmationModal) => {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldFocusAfterRender={false}
      overlayClassName='fixed inset-0 bg-gray-500/75 z-[100]'
      className='absolute  bg-white w-[70%] h-[90%] left-0 right-0 top-6 bottom-6 m-auto my-auto p-auto rounded-[10px]'
    >
      <div className='w-full h-full bg-white grid place-content-center '>
        <div className='animate-spin m-auto  h-32 w-32  border-[20px] border-[#d5d7d5] border-t-[#4DB96B] rounded-full '></div>
        <p className='mt-4 text-[40px]'>Downloading PDF...</p>
      </div>
    </ReactModal>
  );
};

export default LoadingModal;
