import { IButtonWithIcon } from 'interfaces/components/buttonWithIcon';

const ButtonWithIcon = ({
  icon,
  label,
  className,
  onClick,
  id
}: IButtonWithIcon) => {
  return (
    <div className={className} onClick={onClick} id={id}>
      <img src={icon} alt='' />
      <p className='text-lg font-light'>{label}</p>
    </div>
  );
};

export default ButtonWithIcon;
