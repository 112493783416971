import axios from 'axios';

export const getData = async () => {
  return new Promise((resolve, reject) => {
    axios
      // .get('https://private-93fbab-iconiq.apiary-mock.com/getCompsData')
      .get('/api/getData')
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
