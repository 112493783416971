import ReactModal from 'react-modal';
import { IConfirmationModal } from 'interfaces/components/confirmationModal';
import CloseIcon from 'assets/icons/close.svg';
import Button from 'components/Button';

const ConfirmationModal = ({
  isOpen,
  onClose,
  onReset
}: IConfirmationModal) => {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldFocusAfterRender={false}
      overlayClassName='fixed inset-0 bg-gray-500/75 z-[100]'
      className='absolute bg-white w-[500px] max-h-[200px] left-0 right-0 top-6 bottom-6 m-auto my-auto rounded-lg'
    >
      <div className='w-full h-full flex flex-col items-center justify-between p-6'>
        <div className='w-full flex flex-row items-center justify-between'>
          <p className='text-3xl font-light'>Confirm Clear Form</p>
          <img
            className='cursor-pointer'
            src={CloseIcon}
            alt=''
            onClick={onClose}
          />
        </div>
        <p className='text-base font-light'>
          Are you sure you want to clear your data? This will remove any entered
          data from the charts.
        </p>
        <div className='w-full flex flex-row items-center justify-between'>
          <Button
            label='Back'
            id='cancelClearButton'
            onClick={onClose}
            className='w-[129px] h-10 rounded-5xl bg-transparent border border-solid border-gray-500 text-lg font-light  text-center hover:bg-[#504f4f1b] transition-all duration-300'
          />
          <Button
            label='Continue'
            id='clearFormButton'
            onClick={onReset}
            className='w-[129px] h-10 rounded-5xl bg-transparent border border-solid border-gray-500 text-lg font-light  text-center hover:bg-[#504f4f1b] transition-all duration-300'
          />
        </div>
      </div>
    </ReactModal>
  );
};

export default ConfirmationModal;
