import CheckIcon from 'assets/icons/check.svg';
import MailIcon from 'assets/icons/subscribe.svg';

import MailImage from 'assets/images/mail.png';
import TextField from 'components/TextField';
import { sendEmail } from 'network/sendEmail';
import { ChangeEvent, useState } from 'react';
import isEmail from 'validator/es/lib/isEmail';
import './checkbox.css';

const SubscribeSection = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subscribe, setSubscribe] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [subscribedError, setSubscribedError] = useState(false);
  // const [checked, setChecked] = useState(false);

  // const onCheckboxClick = () => {
  //   setChecked(prev => !prev);
  // };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSubscribe(event.target.value);
  };

  const onSubscribe = async () => {
    setIsSubmitting(true);
    await sendEmail(subscribe)
      .then(res => {
        setIsSubmitting(false);
        setSubscribed(true);
      })
      .catch(error => {
        console.error(error);
        setIsSubmitting(false);
        setSubscribedError(true);
      });
  };

  return (
    <div className='w-full bg-white flex flex-col items-center py-14 px-2 gap-8 rounded-lg'>
      <div className='flex flex-col items-center gap-4'>
        <img src={MailImage} alt='' />
        {subscribed ? (
          <p
            className='text-3xl font-light max-w-[610px] text-center'
            id='subscribeSuccessMessage'
          >
            Successfully subscribed!
          </p>
        ) : (
          <p className='text-3xl font-light max-w-[610px] text-center'>
            Subscribe for updates to this tool and the latest insights from
            ICONIQ Growth
          </p>
        )}
        {subscribedError ? (
          <p className='text-red-400'>
            Sorry, we ran into a problem and are looking into the issue.
            <br />
            Please try to subscribe again later.
          </p>
        ) : (
          ''
        )}
      </div>
      <div className='flex flex-col items-center w-full'>
        <TextField
          id='subscribe_button'
          name='subscribe'
          value={subscribe}
          onChange={onChange}
          icon={MailIcon}
          buttonLabel={subscribed ? '' : (isSubmitting ? 'Subscribing...' : 'Subscribe')}
          buttonIcon={CheckIcon}
          onClick={onSubscribe}
          placeholder='Email Address'
          color={subscribed ? '#E7F8EE' : ''}
          // disabled={!isEmail(subscribe) || !checked}
          disabled={!isEmail(subscribe) || isSubmitting}
          readOnly={subscribed}
        />
        {!!subscribe && !isEmail(subscribe) && (
          <p className='text-xs font-light'>Please enter a valid email address</p>
        )}
      </div>
      <div className='flex flex-row items-start justify-center gap-1 mt-[-24px] w-[608px]'>
{/*
        {!subscribed && (
          <input
            name='00NRN000000XzJG'
            className='checkbox !accent-gray-200 !mr-0 text-lg font-light text-left'
            type='checkbox'
            onChange={onCheckboxClick}
            checked={checked}
          />
        )}
*/}
        <p className='text-[14px] font-light leading-[16px] max-w-[568px]'>
          By subscribing, you agree to receive marketing communications from 
          ICONIQ or its affiliates. 
          <br />
          For more information, please see our&nbsp;
          <a
            href='https://www.iconiqcapital.com/privacy/privacy-policies'
            target='_blank'
            rel='noopener noreferrer'
            className='text-[14px] font-light cursor-pointer leading-[16px]'
          >
            Privacy Policy.
          </a>
        </p>
      </div>
    </div>
  );
};

export default SubscribeSection;
