import { Document, Font, pdf } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import { IChartData } from 'interfaces/chartData';
import { saveAs } from 'file-saver';
import { PerformancePage } from './performancePage';
import { ChartPage } from './chartPage';
import { LastPage } from './lastPage';
import font from 'assets/fonts/Quadraat-Light-ICONIQ.ttf';
import { InfoPage } from './infoPage';
import DownloadIcon from 'components/SectionCharts/DownloadIcon';
// import { LoaderIcon } from './loaderIcon';
import { useAppSelector } from 'store';
import { FilterOption } from 'interfaces/components/filter';
import { getCurrentDateFormatted } from 'utils/helpers';
import LoadingModal from 'components/LoadingModal';
import CustomTooltip from 'components/Tooltip';
import ShareIcon from 'assets/icons/share.svg';

interface IDashboardPdf {
  chartData: IChartData[];
  onDownload: () => void;
  onDownloadDone: () => void;
  arr: FilterOption[];
  growthMotion: FilterOption[];
  softwareSector: FilterOption[];
  primaryCustomer: FilterOption[];
  loading: boolean;
}

export const DashboardPdf = ({
  onDownload,
  onDownloadDone,
  arr,
  growthMotion,
  softwareSector,
  primaryCustomer,
  loading
}: IDashboardPdf) => {
  const companyInfo = useAppSelector(state => state.companyInfo);

  const div2PDF = async () => {
    onDownload();
    document.body.classList.add('!overflow-hidden');

    setTimeout(async () => {
      let input: HTMLCollectionOf<Element> =
        window.document.getElementsByClassName('div2PDF0');
      let input1: HTMLCollectionOf<Element> =
        window.document.getElementsByClassName('div2PDF1');
      let input2: HTMLCollectionOf<Element> =
        window.document.getElementsByClassName('div2PDF2');
      let input3: HTMLCollectionOf<Element> =
        window.document.getElementsByClassName('div2PDF3');
      let input4: HTMLCollectionOf<Element> =
        window.document.getElementsByClassName('div2PDF4');
      let input5: HTMLCollectionOf<Element> =
        window.document.getElementsByClassName('div2PDF5');
      let input6: HTMLCollectionOf<Element> =
        window.document.getElementsByClassName('div2PDF6');
      let input7: HTMLCollectionOf<Element> =
        window.document.getElementsByClassName('div2PDF7');
      let input8: HTMLCollectionOf<Element> =
        window.document.getElementsByClassName('div2PDF8');
      let input9: HTMLCollectionOf<Element> =
        window.document.getElementsByClassName('div2PDF9');
      let input10: HTMLCollectionOf<Element> =
        window.document.getElementsByClassName('div2PDF10');
      const canvas = await html2canvas(input[0] as HTMLElement, {
        scale: 2
      });
      const canvas1 = await html2canvas(input1[0] as HTMLElement, {
        scale: 2
      });
      const canvas2 = await html2canvas(input2[0] as HTMLElement, {
        scale: 2
      });
      const canvas3 = await html2canvas(input3[0] as HTMLElement, {
        scale: 2
      });
      const canvas4 = await html2canvas(input4[0] as HTMLElement, {
        scale: 2
      });
      const canvas5 = await html2canvas(input5[0] as HTMLElement, {
        scale: 2
      });
      const canvas6 = await html2canvas(input6[0] as HTMLElement, {
        scale: 2
      });
      const canvas7 = await html2canvas(input7[0] as HTMLElement, {
        scale: 2
      });
      const canvas8 = await html2canvas(input8[0] as HTMLElement, {
        scale: 2
      });
      const canvas9 = await html2canvas(input9[0] as HTMLElement, {
        scale: 2
      });
      const canvas10 = await html2canvas(input10[0] as HTMLElement, {
        scale: 2
      });
      Font.register({
        family: 'Iconiq Quadraat',
        fonts: [{ src: font }]
      });
      const MyDocument = (
        <Document style={{ fontFamily: 'Iconiq Quadraat' }}>
          <InfoPage
            companyInfo={companyInfo}
            arr={arr}
            growthMotion={growthMotion}
            softwareSector={softwareSector}
            primaryCustomer={primaryCustomer}
          />
          <PerformancePage
            image={[canvas.toDataURL('image/png')]}
            pageNumber={2}
            companyInfo={companyInfo}
          />
          <ChartPage
            image={[
              canvas1.toDataURL('image/png'),
              canvas2.toDataURL('image/png')
            ]}
            titles={['Net Dollar Retention', 'Net Magic Number']}
            pageNumber={3}
          />
          <ChartPage
            image={[
              canvas3.toDataURL('image/png'),
              canvas4.toDataURL('image/png')
            ]}
            titles={['Gross Margin', 'Rule of 40']}
            pageNumber={4}
          />
          <ChartPage
            image={[
              canvas5.toDataURL('image/png'),
              canvas6.toDataURL('image/png')
            ]}
            titles={['Burn Multiple', 'ARR per FTE']}
            pageNumber={5}
          />
          <ChartPage
            image={[
              canvas7.toDataURL('image/png'),
              canvas8.toDataURL('image/png')
            ]}
            titles={['OpEx as a % of Revenue', 'FCF Margin']}
            pageNumber={6}
          />
          <ChartPage
            image={[
              canvas9.toDataURL('image/png'),
              canvas10.toDataURL('image/png')
            ]}
            titles={['Quick Ratio', 'Headcount Distribution']}
            pageNumber={7}
          />
          <LastPage />
        </Document>
      );
      const pdfBlob = await pdf(MyDocument).toBlob();
      saveAs(
        pdfBlob,
        `ICONIQ Growth Compass Benchmarks ${getCurrentDateFormatted()}.pdf`
      );
      onDownloadDone();
      document.body.classList.remove('!overflow-hidden');
    }, 500);
  };

  const onShareURLClick = () => {
    navigator.clipboard.writeText(document.URL);
  };

  return (
    <div className='flex flex-row items-center gap-1'>
      <div>
        <button
          className='bg-black flex items-center gap-2 px-5 py-2 rounded-full hover:opacity-90'
          onClick={div2PDF}
          id='download_pdf_button'
          disabled={loading}
        >
          <DownloadIcon />
          <span className='text-white text-lg font-light'>
            Download Your Benchmarks
          </span>
        </button>
        <LoadingModal isOpen={loading} onClose={() => {}} onReset={() => {}} />
      </div>
      <CustomTooltip
        id='share_button'
        content='Share your benchmarks! Click this button to copy a shareable URL to your clipboard'
        icon={ShareIcon}
        onClick={onShareURLClick}
        className='w-9 cursor-pointer'
      />
    </div>
  );
};
