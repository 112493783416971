import { ICard } from 'interfaces/components/card';

const Card = ({ value, description, onUpdate }: ICard) => {
  return (
    <div className='bg-white flex flex-col gap-2 items-start  p-6 rounded-lg w-60 h-29'>
      <div className='flex flex-row w-full justify-between items-center'>
        <p className='text-4xl font-light'>{value}</p>
        {value === 'N/A' && (
          <p
            className='text-sm text-gray-500 cursor-pointer hover:underline'
            onClick={onUpdate}
          >
            Update your data
          </p>
        )}
      </div>

      <p className='text-lg font-light'>{description}</p>
    </div>
  );
};

export default Card;
