import { ITooltip } from 'interfaces/components/tooltip';
import { Tooltip } from 'react-tooltip';
import './tooltip.css';

const CustomTooltip = ({ id, content, icon, className, onClick }: ITooltip) => {
  return (
    <div>
      <span
        data-tooltip-id={id}
        data-tooltip-content={content}
        data-tooltip-place='top'
      >
        <img className={className} src={icon} alt='' onClick={onClick} />
      </span>
      <Tooltip className='tooltip' place='top' id={id} content={content} />
    </div>
  );
};

export default CustomTooltip;
