const Header = () => {
  return (
    <div className='bg-green-100 px-20 py-2'>
      <a
        className='cursor-pointer text-base font-light text-blue-400 no-underline border-none'
        href='https://www.iconiqcapital.com/growth'
        target='_blank'
      >
        Back to ICONIQ Growth
      </a>
    </div>
  );
};

export default Header;
