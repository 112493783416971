import * as SwitchPrimitives from '@radix-ui/react-switch';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { medianColor, topQuartileColor } from 'utils/constants';

interface SwitchProps
  extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
  checkedColor?: string;
  uncheckedColor?: string;
}

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(
  (
    {
      className,
      checkedColor = medianColor,
      uncheckedColor = topQuartileColor,
      ...props
    },
    ref
  ) => {
    return (
      <SwitchPrimitives.Root
        className={twMerge(
          'peer inline-flex h-[24px] w-[38px]  shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors  disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
        style={{
          backgroundColor: props.checked ? checkedColor : uncheckedColor
        }}
        {...props}
        ref={ref}
      >
        <SwitchPrimitives.Thumb
          className={twMerge(
            'pointer-events-none block h-[18px] w-[18px] rounded-full bg-[#fff] shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-[17px] data-[state=unchecked]:translate-x-[3px]'
          )}
        />
      </SwitchPrimitives.Root>
    );
  }
);

Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
