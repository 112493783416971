import { Link, Page, Text, View } from '@react-pdf/renderer';
import { FilterOption } from 'interfaces/components/filter';
import {
  growthMotionFilterOptions,
  primaryCustomerFilterOptions,
  softwareSectorFilterOptions
} from 'utils/constants';
import { ensureHttpProtocol } from 'utils/helpers';
import { PdfFooter } from '../pdfFooter';
import { PdfHeader } from '../pdfHeader';
import { Bullet } from './bullet';
import { InfoDivider } from './infoDivider';
import shortenUrl from 'shorten-url';
interface IInfoPage {
  companyInfo: any;
  arr: FilterOption[];
  growthMotion: FilterOption[];
  softwareSector: FilterOption[];
  primaryCustomer: FilterOption[];
}
export const InfoPage = ({
  companyInfo,
  arr,
  growthMotion,
  softwareSector,
  primaryCustomer
}: IInfoPage) => {
  const Capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const formatIfMissing = (value: any) => {
    return value == null ? '– ' : value;                                         // en dash (not a regular hyphen)
  }

  const companyLink = (
    <Link
      src={ensureHttpProtocol(companyInfo.otherInfo.companyWebsite)}
      style={{ textDecoration: 'underline', color: 'black' }}
    >
      {shortenUrl(`${companyInfo.otherInfo.companyWebsite}`, 20)}
    </Link>
  );

  const InfoLeft = [
    { label: 'Status', value: Capitalize(companyInfo.companyDetails.status) },
    { label: 'Company Website', value: companyLink },
    {
      label: `Current ARR or `,
      value: `$${companyInfo.requiredInfo.currentARR}`,
      label2: 'Annualized Revenue'
    },
    {
      label: 'YoY ARR or Revenue Growth',
      value: `${companyInfo.requiredInfo.yoyARR}%`
    },
    { label: 'FCF Margin', value: `${companyInfo.requiredInfo.fcfMargin}%` },
    {
      label: 'Total Headcount',
      value: companyInfo.requiredInfo.totalHeadcount
    },
    {
      label: 'Rule of 40',
      value: `${
        Number(companyInfo.requiredInfo.yoyARR) +
        Number(companyInfo.requiredInfo.fcfMargin)
      }%`
    },
    {
      label: 'ARR per FTE',
      value: `$${Math.ceil(
        Number(companyInfo.requiredInfo.currentARR) /
          Number(companyInfo.requiredInfo.totalHeadcount)
      )}`,
      last: true
    }
  ];
  const InfoRight = [
    { label: 'Gross Margin', value: `${formatIfMissing(companyInfo.otherInfo.grossMargin)}%` },
    {
      label: 'Net Dollar Retention',
      value: `${formatIfMissing(companyInfo.otherInfo.netDollarRetention)}%`
    },
    {
      label: 'Net Magic Number',
      value: `${formatIfMissing(companyInfo.otherInfo.netMagicNumber)}x`
    },
    { label: 'Burn Multiple', value: `${formatIfMissing(companyInfo.otherInfo.burnMultiple)}x` },
    {
      label: 'Quick Ratio',
      value: `${formatIfMissing(companyInfo.otherInfo.quickRatio)}x`
    },
    {
      label: 'OpEx Function as a',
      label2: '%  of  Revenue',
      value: `G&A: ${formatIfMissing(companyInfo.otherInfo.gA)}%`,
      value1: `R&D: ${formatIfMissing(companyInfo.otherInfo.rD)}%`,
      value2: `S&M: ${formatIfMissing(companyInfo.otherInfo.sM)}%`
    },
    {
      label: 'Headcount Distribution',
      value: `G&A: ${formatIfMissing(companyInfo.otherInfo.gAHead)}`,
      value1: `R&D: ${formatIfMissing(companyInfo.otherInfo.rDHead)}`,
      value2: `S&M: ${formatIfMissing(companyInfo.otherInfo.sMHead)}`,
      last: true
    }
  ];
  return (
    <Page size='A4' orientation='landscape'>
      <PdfHeader />
      <View style={{ padding: '0 24px', fontSize: '12px', marginTop: '24px' }}>
        <Text style={{ marginTop: '24px' }}>
          This report provides a comparison of the data you entered in Compass
          with our SaaS benchmarks¹. The information presented here is for
          illustrative purposes only, and we welcome you to contact us at{' '}
          <Link>ICONIQGrowthInsights@iconiqcapital.com</Link> with any
          questions.
        </Text>
      </View>
      <View
        style={{
          padding: '24px',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <View
          style={{ width: '389px', marginRight: '16px', maxHeight: '290px' }}
        >
          <Text style={{ fontSize: '20px', marginBottom: '8px' }}>
            Your Information
          </Text>
          <Text
            style={{ fontSize: '10px', marginBottom: '16px', color: '#5c5c5c' }}
          >
            Below, you’ll find the user parameters you provided, along with
            corresponding graphs that visualize these comparisons.
          </Text>
          <View
            style={{
              border: '1px solid #dddddd',
              borderRadius: '4px',
              padding: '16px',
              display: 'flex',
              flexDirection: 'row',
              gap: '24px'
            }}
          >
            <View>
              {InfoLeft.map((item, index) => (
                <InfoDivider
                  key={index}
                  label={item.label}
                  value={item.value}
                  last={item.last ?? false}
                  label2={item.label2}
                />
              ))}
            </View>
            <View>
              <View>
                {InfoRight.map((item, index) => (
                  <InfoDivider
                    key={index}
                    label={item.label}
                    value={item.value}
                    last={item.last ?? false}
                    label2={item.label2}
                    value2={item.value2}
                    value1={item.value1}
                  />
                ))}
              </View>
            </View>
          </View>
        </View>
        <View style={{ width: '389px' }}>
          <Text style={{ fontSize: '20px', marginBottom: '8px' }}>
            Selected Filters
          </Text>
          <Text
            style={{ fontSize: '10px', marginBottom: '16px', color: '#5c5c5c' }}
          >
            Below, you’ll find the user parameters you provided, along with
            corresponding graphs that visualize these comparisons.
          </Text>
          <View
            style={{
              border: '1px solid #dddddd',
              borderRadius: '4px',
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              fontSize: '10px'
            }}
          >
            <View
              style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}
            >
              <View style={{ width: '170.5px', marginRight: '16px' }}>
                <View style={{ marginBottom: '16px' }}>
                  <Text
                    style={{
                      color: '#5c5c5c',
                      fontSize: '10px',
                      marginBottom: '8px'
                    }}
                  >
                    Software Sector
                  </Text>
                  {softwareSectorFilterOptions.map(filter => (
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: '2px',
                        gap: '4px'
                      }}
                    >
                      {softwareSector.find(
                        arrFilter => arrFilter.value === filter.value
                      ) && <Bullet />}
                      <Text
                        style={{
                          color: softwareSector.find(
                            filterValue => filterValue.value === filter.value
                          )
                            ? ''
                            : '#9B9B9B'
                        }}
                      >
                        {filter.label}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
              <View>
                <Text
                  style={{
                    color: '#5c5c5c',
                    fontSize: '10px',
                    marginBottom: '8px'
                  }}
                >
                  Primary Customer
                </Text>
                {primaryCustomerFilterOptions.map(filter => (
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: '2px',
                      gap: '4px'
                    }}
                  >
                    {primaryCustomer.find(
                      arrFilter => arrFilter.value === filter.value
                    ) && <Bullet />}
                    <Text
                      style={{
                        color: primaryCustomer.find(
                          filterValue => filterValue.value === filter.value
                        )
                          ? ''
                          : '#9B9B9B'
                      }}
                    >
                      {filter.label}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
            <View style={{ width: '170px' }}>
              <Text
                style={{
                  color: '#5c5c5c',
                  marginBottom: '8px'
                }}
              >
                Growth Motion
              </Text>
              {growthMotionFilterOptions.map((item, index) => (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '2px',
                    gap: '4px'
                  }}
                >
                  {growthMotion.find(
                    arrFilter => arrFilter.value === item.value
                  ) && <Bullet />}
                  <Text
                    style={{
                      color: growthMotion.find(
                        filterValue => filterValue.value === item.value
                      )
                        ? ''
                        : '#9B9B9B'
                    }}
                  >
                    {item.label}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <Text
            style={{ fontSize: '10px', marginTop: '8px', color: '#5c5c5c' }}
          >
            Note: Selected filters are shown in bold with a bullet point
          </Text>
        </View>
      </View>
      <View style={{ padding: '24px' }}>
        <Text style={{ fontSize: '10px', color: '#5c5c5c' }}>
          The numbers, figures and case studies included in this tool have been
          included for purposes of illustration only, and no assurance can be
          given that the actual results achieved by any ICONIQ portfolio or any
          of ICONIQ's partners or affiliates will correspond with the results
          contemplated in the tool. Past performance is not a reliable indicator
          of future performance, and any investment may result in the loss of
          all or some of the amounts invested.
        </Text>
      </View>
      <View
        style={{
          position: 'absolute',
          bottom: '16px',
          padding: '0 24px',
          width: '100%'
        }}
      >
        <PdfFooter pageNumber={1} />
      </View>
    </Page>
  );
};
