import { Image, Text, View } from '@react-pdf/renderer';
export interface IPdfChart {
  title: string;
  img: string;
  height?: string;
}
export const PdfChart = ({ title, img, height }: IPdfChart) => {
  const formulas: { [key: string]: any } = {
    'YoY ARR Growth': 'Year 2 ARR / Year 1 ARR - 1',
    'Net Dollar Retention':
      '1 + (Expansion ARR - Gross Churn ARR) / Average (BOP ARR + EOP ARR)',
    'Net Magic Number': 'Net New ARR / S&M Expense',
    'Gross Margin': 'Gross Profit / Revenue',
    'Rule of 40': 'ARR Growth YoY % + FCF Margin %',
    'Burn Multiple': 'Net Burn / Net New ARR',
    'ARR per FTE': 'ARR / Total Headcount',
    'OpEx as a % of Revenue': 'Median OpEx by Function / Revenue',
    'FCF Margin': 'FCF / Revenue',
    'Quick Ratio': 'Gross New ARR / Gross Churned ARR',
    'Headcount Distribution':
      'Average Headcount by Function / Total Company Headcount'
  };
  return (
    <View
      style={{
        width: '794px',
        // height: height ?? '230px',
        borderRadius: '4px',
        padding: '8px 8px 2px 8px',
        border: '1px solid #dddddd',
        fontSize: '16px'
      }}
    >
      <Text>{title}</Text>
      <Text
        style={{
          fontSize: '9px',

          marginBottom: '1px',
          marginTop: '1px',
          color: '#5c5c5c'
        }}
      >
        {formulas[title]}
      </Text>
      <View>
        <Image src={img} />
      </View>
    </View>
  );
};
