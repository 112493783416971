import axios from 'axios';

export const sendEmail = (email: string) => {
  return new Promise((resolve, reject) => {
    // Check if the email is blank
    if (!email) {
      console.error('Email is missing in the request body');
      return reject({ status: 400, message: 'Email is required.' });
    }

    let data = {
      'email': email,
      '00NRN000000XzJG': '1',                                                   // opt-in checkbox
      '00NRN000000XzJH': ''                                                     // ip address
    }

    axios.get('https://api.ipify.org?format=json')                              // fetch the ip address
      .then(ipResponse => {
        data['00NRN000000XzJH'] = ipResponse.data.ip;                           // add the ip address if successful
      })
      .catch(error => {
        console.error('Error fetching IP:', error);                             // leave ip null if any errors fetching
      })
      .finally(() => {
        axios.post('/api/subscribe', data)                                      // proceed with the main subscribe request
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.error('Error posting subscription:', error);
            reject(error);
          });
      });
  });
};