import { Page, View } from '@react-pdf/renderer';
import { PdfChart } from '../pdfChart';
import { PdfHeader } from '../pdfHeader';
import { PdfDescription } from '../pdfDescription';
import { PdfGrowthInterprise } from '../pdfGrowthInterprise';
import { PdfFooter } from '../pdfFooter';
export interface IPage {
  image: string[];
  pageNumber: number;
  titles?: string[];
  companyInfo?: any;
}
export const PerformancePage = ({ image, pageNumber, companyInfo }: IPage) => {
  return (
    <Page size='A4' orientation='landscape'>
      <View style={{ maxHeight: '580px' }}>
        <PdfHeader />
        <View style={{ padding: '0 24px', marginTop: '24px' }}>
          <PdfDescription />
          <PdfGrowthInterprise companyInfo={companyInfo} />
          <View style={{ marginTop: '16px' }}>
            <PdfChart height='263px' img={image[0]} title='YoY ARR Growth' />
          </View>
          <View style={{ marginTop: '16px' }}>
            <PdfFooter pageNumber={pageNumber} />
          </View>
        </View>
      </View>
    </Page>
  );
};
