import { Image, Text, View } from '@react-pdf/renderer';
import CompassImage from 'assets/images/iconiq-compass-logo.png';
export const PdfHeader = () => {
  return (
    <View
      style={{
        width: '100%',
        height: '64px',
        backgroundColor: '#EDF8F0',
        paddingLeft: '24px',
        paddingRight: '24px',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row',
        position: 'relative'
      }}
    >
      <Text style={{ marginTop: '24px' }}>ICONIQ | Growth</Text>
      <View style={{ width: '191px', height: '54px' }}>
        <Image src={CompassImage} />
      </View>
    </View>
  );
};
