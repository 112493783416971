import { useClickAway } from '@uidotdev/usehooks';
import Button from 'components/Button';
import { Filter } from 'components/Filter';
import { IFiltersSection } from 'interfaces/components/filtersSection';
import { useState } from 'react';
import {
  growthMotionFilterOptions,
  primaryCustomerFilterOptions,
  softwareSectorFilterOptions
} from 'utils/constants';

const FiltersSection = ({ handleApply }: IFiltersSection) => {
  const [arrFilter, setArrFilter] = useState([]);
  const [softwareSectorFilter, setSoftwareSectorFilter] = useState([]);
  const [growthMotionFilter, setGrowthMotionFilter] = useState([]);
  const [primaryCustomerFilter, setPrimaryCustomerFilter] = useState([]);

  // const arrRef = useClickAway<HTMLInputElement>(() => {
  //   const parent = document.getElementsByClassName('dropdown-container')[0];
  //   const elem = document.getElementsByClassName('dropdown-heading')[0];
  //   elem &&
  //     parent &&
  //     parent.ariaExpanded === 'true' &&
  //     (elem as HTMLElement).click();
  // });

  const softwareSectorRef = useClickAway<HTMLInputElement>(() => {
    const parent = document.getElementsByClassName('dropdown-container')[0];
    const elem = document.getElementsByClassName('dropdown-heading')[0];
    elem &&
      parent &&
      parent.ariaExpanded === 'true' &&
      (elem as HTMLElement).click();
  });

  const growthMotionRef = useClickAway<HTMLInputElement>(() => {
    const parent = document.getElementsByClassName('dropdown-container')[1];
    const elem = document.getElementsByClassName('dropdown-heading')[1];
    elem &&
      parent &&
      parent.ariaExpanded === 'true' &&
      (elem as HTMLElement).click();
  });

  const primaryCustomerRef = useClickAway<HTMLInputElement>(() => {
    const parent = document.getElementsByClassName('dropdown-container')[2];
    const elem = document.getElementsByClassName('dropdown-heading')[2];
    elem &&
      parent &&
      parent.ariaExpanded === 'true' &&
      (elem as HTMLElement).click();
  });

  const onhandleApply = () => {
    handleApply(
      arrFilter,
      softwareSectorFilter,
      growthMotionFilter,
      primaryCustomerFilter
    );
  };

  const onResetFilters = () => {
    setArrFilter([]);
    setSoftwareSectorFilter([]);
    setGrowthMotionFilter([]);
    setPrimaryCustomerFilter([]);
    handleApply([], [], [], []);
  };

  return (
    <div className='w-full flex flex-row justify-between items-end gap-6 rounded-2xl'>
      {/* <Filter
        id='arr_filter'
        label='ARR'
        options={arrFilterOptions}
        value={arrFilter}
        onChange={setArrFilter}
        ref={arrRef}
      /> */}
      <Filter
        id='software_sector_filter'
        label='Software Sector'
        options={softwareSectorFilterOptions}
        value={softwareSectorFilter}
        onChange={setSoftwareSectorFilter}
        ref={softwareSectorRef}
      />
      <Filter
        id='growth_motion_filter'
        label='Growth Motion'
        options={growthMotionFilterOptions}
        value={growthMotionFilter}
        onChange={setGrowthMotionFilter}
        ref={growthMotionRef}
      />
      <Filter
        id='primary_customer_filter'
        label='Primary Customer Segment'
        options={primaryCustomerFilterOptions}
        value={primaryCustomerFilter}
        onChange={setPrimaryCustomerFilter}
        ref={primaryCustomerRef}
      />
      <Button
        label='Reset Filters'
        id='resetFilters'
        onClick={onResetFilters}
        className='w-[129px] h-10 rounded-5xl bg-transparent border border-solid border-gray-500 text-lg font-light text-center hover:bg-[#504f4f1b] transition-all duration-300'
      />
      <Button
        label='Apply'
        id='applyFilters'
        onClick={onhandleApply}
        className='w-21 h-10 rounded-5xl bg-transparent border border-solid border-gray-500 text-lg font-light text-center text-white bg-[#151515] hover:bg-[#404040] transition-all duration-300'
      />
    </div>
  );
};

export default FiltersSection;
