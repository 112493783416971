import { Text, View } from '@react-pdf/renderer';
import { PerformanceMetricsCard } from './perfomanceMetricsCard';
import { formatMoney, formatThousands } from 'utils/helpers';
interface IPdfGrowthInterprise {
  companyInfo: any;
}
export const PdfGrowthInterprise = ({ companyInfo }: IPdfGrowthInterprise) => {
  const metrics = [
    {
      percentage: companyInfo.requiredInfo.yoyARR
        ? `${companyInfo.requiredInfo.yoyARR}%`
        : 'N/A',
      text: 'ARR Growth'
    },
    {
      percentage: companyInfo.otherInfo.netDollarRetention
        ? `${companyInfo.otherInfo.netDollarRetention}%`
        : 'N/A',
      text: 'Net Dollar Retention'
    },
    {
      percentage:
        companyInfo.requiredInfo.yoyARR && companyInfo.requiredInfo.fcfMargin
          ? `${
              companyInfo.requiredInfo.yoyARR +
              companyInfo.requiredInfo.fcfMargin
            }%`
          : 'N/A',
      text: 'Rule of 40'
    },
    {
      percentage: companyInfo.otherInfo.netMagicNumber
        ? `${companyInfo.otherInfo.netMagicNumber}x`
        : 'N/A',
      text: 'Net Magic Number'
    },
    {
      percentage:
        companyInfo.requiredInfo.currentARR &&
        companyInfo.requiredInfo.totalHeadcount
          ? `$${formatThousands(
              companyInfo.requiredInfo.currentARR /
                companyInfo.requiredInfo.totalHeadcount,
              0
            )}`
          : 'N/A',
      text: 'ARR per FTE'
    }
  ];
  return (
    <View style={{ marginTop: '16px' }}>
      <Text style={{ marginBottom: '12px', fontSize: '12px' }}>
        Your Performance Metrics
      </Text>
      <View style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
        {metrics.map(metric => (
          <PerformanceMetricsCard
            percentage={metric.percentage}
            text={metric.text}
          />
        ))}
      </View>
    </View>
  );
};
