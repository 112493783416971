import { View, Text, StyleSheet } from '@react-pdf/renderer';

interface IInfoDivider {
  label: string;
  value: string;
  last?: boolean;
  label2?: string;
  value1?: string;
  value2?: string;
}
export const InfoDivider = ({
  label,
  value,
  last = false,
  label2,
  value1,
  value2
}: IInfoDivider) => {
  const styles = StyleSheet.create({
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontSize: 10, // fontSize in points, not px
      width: 167,
      borderBottom: '1px solid #dddddd', // Apply border
      height: 18,
      marginBottom: 2 // Add spacing if needed
    },
    label: {
      color: '#5c5c5c',
      maxWidth: 116,
      whiteSpace: 'pre-wrap' // Ensure line breaks are respected
    },
    value: {
      // Add styles for value if needed
    }
  });
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: '10px',
        width: '167px',
        borderBottom: !last ? '1px solid #dddddd' : ''
        // height: '18px'
      }}
    >
      <View style={{ flexDirection: 'column' }}>
        <Text style={styles.label}>{label}</Text>
        <Text style={styles.label}>{label2 ?? ''}</Text>
      </View>
      <View style={{ flexDirection: 'column' }}>
        <Text wrap style={{ maxWidth: '90px', maxHeight: '50px' }}>
          {value}
        </Text>
        {value1 && <Text>{value1}</Text>}
        {value2 && <Text>{value2}</Text>}
      </View>
    </View>
  );
};
