import { Link, Text, View } from '@react-pdf/renderer';

export const PdfDescription = () => {
  return (
    <View>
      <Text style={{ fontSize: '20px', marginBottom: '8px' }}>
        ICONIQ Growth Enterprise Five
      </Text>
      <Text style={{ fontSize: '10px' }}>
        The ICONIQ Growth Enterprise Five can help you determine the health of
        your business. Often startups include these metrics in regularly
        reporting to understand drivers of topline and bottomline performance
        and support strategic planning decisions around everything from GTM
        strategy to team size. Find out more about the ICONIQ Growth Enterprise
        Five{' '}
        <Link href=' https://www.iconiqcapital.com/growth/insights/iconiq-growth-enterprise-five'>
          here.
        </Link>
      </Text>
    </View>
  );
};
