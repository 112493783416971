import { IButton } from 'interfaces/components/button';

const Button = ({ label, onClick, className, disabled, id }: IButton) => {
  return (
    <button id={id} className={className} onClick={onClick} disabled={disabled}>
      {label}
    </button>
  );
};

export default Button;
