import { ILinksCard } from 'interfaces/components/linksCard';

const LinksCard = ({ title, description, link }: ILinksCard) => {
  return (
    <a
      className='articlePanel flex flex-col items-start gap-4 max-w-[298px] min-h-[159px] p-4 bg-white rounded-lg border-none hover:shadow-lg hover:opacity-85'
      href={link}
      target='_blank'
      rel='noopener noreferrer'
      data-url={link}
      data-title={description}
    >
      <p className='text-base font-light text-gray-700'>{title}</p>
      <p className='text-2xl font-light leading-[28.8px]'>{description}</p>
    </a>
  );

};

export default LinksCard;
