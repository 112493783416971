import { IRadio } from 'interfaces/components/radio';

const Radio = ({ name, value, selected, onChange, label }: IRadio) => {
  const handleChange = () => {
    onChange(value);
  };

  return (
    <div className='flex flex-row justify-start items-center gap-2'>
      <div
        className={`w-5 h-5 flex items-center justify-center border border-solid rounded-full cursor-pointer ${
          value === selected ? 'border-green-400' : 'border-gray-500'
        }`}
        onClick={handleChange}
      >
        {value === selected && (
          <div className='w-[14px] h-[14px] bg-green-400 rounded-full'></div>
        )}
      </div>
      <p className='text-lg font-light'>{label}</p>
    </div>
  );
};

export default Radio;
